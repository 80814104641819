import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BCWhyVideo from '../assets/BCWhy.mp4'
import jamie from '../assets/jamie.JPG';
import julia from '../assets/julia.jpeg';
import andy from '../assets/andy-b.jpeg';
import danny from '../assets/danny.JPG';
import will from '../assets/will.JPG';
import mar from '../assets/marzena.jpeg';
import { FaVolumeDown } from "react-icons/fa";

const WhyPage = () => {

  const [sound, setSound] = useState('muted')

  const handleSound = () => {
    if(sound === 'muted') {
      setSound('')
    } else
    {
      setSound('muted')
    }
  }

  return (
    <Wrapper>
      <div className="whyBCtalk">
        <div className="content">
          <h2>Why learn Bachata</h2>
          <p>Here, Julia and Andy talk about about why you should start learning Bachata. </p>
        </div>
          <div className ="videocontainer">
            <video src={BCWhyVideo} autoPlay loop muted={sound} />

            <button className="control" onClick={handleSound}><FaVolumeDown icon="fa-solid " /></button>
          </div>

      </div>
      <h2>Learn about our amazing dance instructors and what they can offer...</h2>
      <div className="teachers-left" >

          <div className="teachers__image-container">
            <img src={julia} alt="Julia - Teacher " className="teachers__image"/>
          </div>
          <div className="teachers___info">
          <h1 class="teacher__name">Julia Sulimenko</h1>
          <p>My name is Julia and my passion for dance started at a young age and has only grown over the years. I’ve trained in various styles, including Contemporary, Latin Ballroom, Argentine Tango, and Belly Dance, but my heart belongs to Salsa, Bachata, and Latin music.
With vast experience performing at various events, entering competitions, and auditioning for dance productions, I have honed my skills both on stage and in the studio. Teaching and performing have been a major part of my journey, and in January 2023, I founded Bachata Connection—a hub for all things Bachata. As a Bachata Ambassador on the South Coast of England, I love sharing my passion by organizing events and building a vibrant dance community. Dance is my energy, my joy, and an essential part of my life—I couldn’t imagine it any other way!</p>
          </div>
      </div>
      <div className="teachers-right">
        <div className="teachers__image-container">
          <img src={andy} alt="Andy - Teacher " className="teachers__image"/>
        </div>
          <div className="teachers___info">
          <h1 class="teacher__name">Andy Barnetson</h1>
          <p>My name is Andy and I am one of the Bachata Connection founders and teachers. I love teaching and it is rewarding to see students' progress and development. I have been dancing for many years; I started with Salsa and continued my dance journey, mastering Sensual Bachata. Dancing became an important part of my life - it is great for socialising as well as helps with stress and anxiety.</p>
          </div>
      </div>
      <div className="teachers-left ">
        <div className="teachers__image-container">
          <img src={jamie} alt="Jamie - Teacher " className="teachers__image"/>
        </div>
        <div className="teachers___info">
        <h1 class="teacher__name">Jamie Mannering</h1>
        <p>My name is Jamie and my passion for Bachata has taken me around the world, from dancing at international festivals to teaching in countries like Malta and Egypt. I even spent time living in Egypt to deepen my dance knowledge and skills.
Teaching with Bachata Connection has been an incredible journey, and I love being part of this amazing community. Sharing the joy of Bachata and seeing the connections it creates is what inspires me every day!
</p>
        </div>
      </div>
      <div className="teachers-right">
        <div className="teachers__image-container">
          <img src={danny} alt="Danny - Teacher " className="teachers__image"/>
        </div>
          <div className="teachers___info">
          <h1 class="teacher__name">Danny  Romero</h1>
          <p>My name is Danny and dance is more than just a passion for me—it’s a way of life. Bachata and Salsa are at the heart of my journey, but I’ve also explored Argentine Tango, Kizomba, Brazilian Zouk, Ballroom Latin, and more. Each style has deepened my connection to music, movement, and culture, making my dance journey incredibly fulfilling.
Throughout the years, dancing has brought me not only joy but also incredible experiences and connections with amazing people. The energy, rhythm, and expression that dance offers make it an essential part of who I am, and I can’t imagine life without it!</p>
          </div>
      </div>
      <div className="teachers-left ">
        <div className="teachers__image-container">
          <img src={will} alt="William - Teacher " className="teachers__image"/>
        </div>
        <div className="teachers___info">
        <h1 class="teacher__name">William Hernandez Abreu </h1>
        <p>Born in Havana, Cuba, I have been dancing since childhood. I graduated from the renowned José María Romero Escuela Nacional de Show Arts & Espectáculos and have performed internationally in Cuba, Spain, Poland, and the UK. My experience includes Cuban television, top theatres like Teatro América and Karl Marx Theatre, and collaborations with artists such as Charanga Habanera, Pablo FG, and Dan Den Grupo.
Cuban Salsa is my passion—it's more than just a dance; it's a way of life. I love sharing its energy, rhythm, and cultural roots, whether through teaching, performing, or DJing. Expanding into music production, I blend Afro-Cuban influences with Salsa, Bachata, Reggaeton, R&B, Hip Hop, and Deep House. As DJ Pupito, I have played at international venues and regularly perform across the UK, including London, Bristol, Cardiff, Brighton, and the Isle of Wight.</p>
        </div>
      </div>
      <div className="teachers-right">
        <div className="teachers__image-container">
          <img src={mar} alt="Marzena - Teacher " className="teachers__image"/>
        </div>
          <div className="teachers___info">
          <h1 class="teacher__name">Marzena Bober</h1>
          <p>My name is Marzena, and I’m passionate about dance, fitness, and beauty! Dancing has been a part of my life since I was eight, allowing me to express emotions, connect with others, and find joy in movement. I started with Salsa and ballroom dance in Poland, where I had the privilege of assisting my teacher at a young age, sparking my love for teaching.
Today, I specialize in Salsa, Bachata, Lady Styling, and Sensual Dance, helping my students build confidence, embrace self-expression, and experience the joy of dance. I also draw inspiration from Contemporary Dance, Jazz, and Kizomba, constantly growing as a dancer and instructor. My goal is to create a welcoming space where everyone can feel the magic of movement and discover their own unique dance journey.</p>
          </div>
      </div>




    </Wrapper>
  )

}

const Wrapper = styled.section`

  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: var(--clr-bcgrey);

  h2 {
      text-align: center;
      margin: 0 auto;
      max-width: 1500px;
      display: block;
      color: var(--clr-primary-1);
      margin-bottom: 1rem;
  }

  .content {
    display: block;
    margin: 1rem 1rem ;

    border-radius: 4px;

  }

  .content p {
    color: #6b6b6b;
    line-height: 1.6;
    margin-bottom: 0;

  }

  .whyBCtalk {

  }

  video {
    width: 100%;
    height: 33vh;
    object-fit: cover;
    position: relative;
    background-size: cover;


  }
  .teachers__image-container  {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
  }
  .teachers__image {
    width: 100%;
    vertical-align: top;
  }
  .teachers___info {
    text-align: right;
    padding: 0.9rem;
    display: inline-block;

  }

  .teachers___info p {
    color: #6b6b6b;
    line-height: 1.6;
    margin-bottom: 0;
  }
  .teachers-right {
    margin-bottom: 2rem;

  }

  .teachers-left {
    margin-bottom: 2rem;

  }
  .teachers-container {
    background-color: var(--clr-bclghtgrey);
    /* padding: 2rem; */
    /* max-width: 1200px; */
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .teacher__name {
    margin: 0.2rem;
    color: var(--clr-primary-1);
    font-size: 2rem;
  }

  .teachers-right > .teachers___info {
    text-align: left;
  }

  .videocontainer {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
  }
  .control {
    position: absolute;
    bottom: 2%;
    left:0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    padding: 5px 15px;
    cursor: pointer;
    margin: 0 0.5rem;
  }



  @media (min-width: 40rem) {
    .content {
      margin: 1rem 0rem;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      background-color: var(--clr-bclghtgrey);
      padding: 2rem;
    }

    .content h2 {

    }
    .whyBCtalk {
      max-width: 1500px;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      margin: 0rem auto;

    }

    .content p {
      padding: 0;

    }

    h2 {
      max-width: 1500px;
      display: flex;
    }

    video {
    width: 100%;
    height: 66vh;
    object-fit: cover;
    box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.3);
    border-radius: 4px;
    }


    .teachers-left {
      margin: 3rem auto;
      max-width: 1500px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      /* background-color: var(--clr-bclghtgrey);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */

    }


    .teachers__image-container  {
      display: inline-block;
      vertical-align: middle;
      width: 30rem;
      box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.3)
    }

    .teachers__image {
      width: 100%;
      vertical-align: top;
    }
    .teacher__name {
      margin: 0.2rem;
      color: var(--clr-primary-1);
      font-size: 2rem;
    }

    .teachers___info {
      text-align: right;
      padding: 0.9rem;
      display: inline-block;
      width: 40rem;
      padding: 2rem;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      background-color: var(--clr-bclghtgrey);
      margin: 1rem;
    }
    .teachers-right {
      margin: 3rem auto;
      max-width: 1500px;
      display: flex;
      align-items: center;
      justify-content: space-around; /*
      background-color: var(--clr-bclghtgrey); */
    }



    .teachers-right > .teachers___info {
      text-align: left;
    }

  }

`
export default WhyPage
